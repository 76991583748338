<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <ValidationObserver ref="searchForm" v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit()" >
            <b-overlay :show="unitLoad">
              <template>
                <div style="font-size:18px; background-color: #005B5B;margin-bottom: 12px">
                  <h5 class="text-white ml-2"> {{ $t('externalLrcpn.service_information') }} </h5>
                </div>
              </template>
              <b-row>
                <b-col lg="4" sm="4">
                  <ValidationProvider name="Organization" vid="org_id" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="org_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('user_role.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="gridSearch.org_id"
                        :options="orgList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="4" sm="4">
                  <ValidationProvider name="Service Name" vid="service_id" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="service_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('org_pro.service_namel')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        v-model="gridSearch.service_id"
                        :options="serviceNamesList"
                        id="service_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                      >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="2" sm="2" md="2" v-if="parseInt(gridSearch.service_id) === 5 || parseInt(gridSearch.service_id) === 6 || parseInt(gridSearch.service_id) === 23">
                    <ValidationProvider name="file No" vid="file_no">
                      <b-form-group
                          class="row"
                          label-cols-sm="12"
                          label-for="file_no"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('li_step.bin_no') }}  <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                          plain
                          v-model="gridSearch.file_no"
                          id="file_no"
                          disabled
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                        </b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="3" sm="3">
                  <ValidationProvider name="Had manual" vid="has_manual" v-if="isShowManual === true">
                      <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="has_manual"
                      >
                      <template v-slot:label>
                          {{$t('externalLrcpn.has_manual')}}
                      </template>
                      <b-form-radio-group
                        v-model="prevManualData.hasManual"
                        :options="manualList"
                        :disabled="hasManualDisabled"
                      >
                      </b-form-radio-group>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>
        </ValidationObserver>
        <!-- <pre>{{ prevManualData }}</pre> -->
        <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
          <b-form  @submit.prevent="handleSubmit(saveFormData)" @reset.prevent="reset" v-if="tindex > 0">
            <b-overlay :show="saveloading">
              <hr v-if="prevManualData.hasManual === true">
              <template v-if="prevManualData.hasManual === true">
                  <div style="font-size:18px; background-color: #005B5B;margin-bottom: 12px">
                    <h5 class="text-white ml-2"> {{ $t('externalLrcpn.prev_information') }} </h5>
                  </div>
              </template>
              <b-row v-if="prevManualData.hasManual === true">
                <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Application Id" vid="generate_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="generate_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('externalLrcpn.prev_license_no') }} <span class="text-danger">*</span>
                        </template>
                        <b-input-group size="md" :prepend="moaLic">
                          <b-form-input
                          plain
                          :type="[11, 19].includes(gridSearch.service_id) ? 'text' : 'number'"
                          v-model="prevManualData.generate_id"
                          id="application_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-input>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                        </b-input-group>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Book No" vid="book_no">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="book_no"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('externalLrcpn.book_no') }}
                        </template>
                        <b-form-input
                        plain
                        v-model="prevManualData.book_no"
                        id="application_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Issue Date" vid="issue_date">
                        <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('externalLrcpn.first_issue')"
                        label-for="from_date"
                        slot-scope="{ valid, errors }"
                        >
                        <b-form-input
                            id="datepicker"
                            placeholder="yyyy-mm-dd"
                            v-model="prevManualData.issue_date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <slot v-if="[1, 17, 19].includes(gridSearch.service_id)">
                  <b-col lg="4" sm="4" md="4">
                    <ValidationProvider name="Reniew Issue Date" vid="reniew_issue_date" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="reniew_issue_date"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('externalLrcpn.last_renew_issue_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="datepicker"
                        placeholder="yyyy-mm-dd"
                        v-model="prevManualData.reniew_issue_date"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="2" sm="2" md="2">
                    <br/>
                    <b-button @click="oldApplicationInfo()"  class="btn btn-md mt-2" variant="primary">{{ $t('globalTrans.search') }}</b-button>
                  </b-col>
                </slot>
                <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Expire Date" vid="expire_date">
                        <b-form-group
                        class="row"
                        label-cols-sm="12"
                        :label="$t('externalLrcpn.last_issue')"
                        label-for="expire_date"
                        slot-scope="{ valid, errors }"
                        >
                        <b-form-input
                            id="expireDate"
                            placeholder="yyyy-mm-dd"
                            v-model="prevManualData.expire_date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                    <ValidationProvider name="attachment" vid="attachment" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="attachment"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('externalLrcpn.prev_license')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-file
                            id="attachment"
                            v-on:change="onFileChange"
                            v-model="prevManualData.old_attachment"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            <p class="text-danger mt-2" style="font-size: 13px;">{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is PNG/JPG/DOC/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট PNG/JPG/DOC/JPEG/PDF]' }}</p>
                        </b-form-group>
                    </ValidationProvider>
                    <slot v-if="prevManualData.old_attachment[0]">
                      <span>{{ $t('admission_form.docu') }}</span>
                      <button type="button" class="btn btn-sm btn-success ml-2">
                        <a target="_blank" :href="(lrcpanBaseUrl + 'uploads/lrcpn/' + prevManualData.old_attachment[0])"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                      </button>
                    </slot>
                </b-col>
              </b-row>
               <hr>
              <template>
                  <div style="font-size:18px; background-color: #005B5B;margin-bottom: 12px">
                    <h5 class="text-white ml-2"> {{ $t('externalLrcpn.office_information') }} </h5>
                  </div>
              </template>
                  <b-overlay v-if="!officeCategoryShow">
                    <b-row v-if="!isOfficeSelected">
                      <slot v-if="!([3, 10, 11, 19].includes(gridSearch.service_id))">
                        <b-col lg="6" sm="12" v-if="locationShow">
                          <ValidationProvider name="Area Type" vid="area_type_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="area_type_id "
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="officeData.area_type_id"
                                  id="area_type_id"
                                  :options="areaTypeList"
                                  @change="getAreaTypeData(officeData.area_type_id)"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="[3, 10, 11, 19].includes(gridSearch.service_id)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </slot>
                      <b-col lg="6" sm="12" v-if="ItemShow">
                      <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="officeData.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="seedOfficeCheck()"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12" v-if="ItemShow">
                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="officeData.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                :disabled="seedOfficeCheck()"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12" v-if="PauroshobaItemShow || UnionItemShow">
                          <ValidationProvider name="Upazila" vid="upazila_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="upazila_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="officeData.upazilla_id"
                                  :options="upazilaList"
                                  id="upazila_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="seedOfficeCheck()"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12" v-if="CityCorpItemShow">
                        <ValidationProvider name="City Corporation" vid="city_corporation_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="city_corporation_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="officeData.city_corporation_id"
                                :options="cityCorporationList"
                                id="city_corporation_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                  </b-overlay>
                  <b-row>
                 <b-col lg="6" sm="12" v-if="officeCategoryShow">
                  <ValidationProvider name="Office Category" vid="office_cat_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_cat_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('globalTrans.office_cat') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="officeData.office_cat_id"
                          :options="officeCategoryList"
                          id="office_cat_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                 <b-col lg="6" sm="12"  v-if="officeTypeShow">
                  <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_type_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('elearning_config.office_type') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="officeData.office_type_id"
                          :options="officeTypeList"
                          :disabled="[3, 10, 11, 19].includes(gridSearch.service_id)"
                          id="office_type_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12" v-if="officeData.office_cat_id === 1 || officeData.office_cat_id === 2 || officeData.office_cat_id === 3">
                  <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="division_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="officeData.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12" v-if="officeData.office_cat_id === 2 || officeData.office_cat_id === 3">
                  <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="district_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="officeData.district_id"
                          :options="districtList"
                          id="district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                 <b-col lg="6" sm="12" v-if="officeData.office_cat_id === 3">
                    <ValidationProvider name="Upazila" vid="upazila_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="upazila_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="officeData.upazilla_id"
                            :options="upazilaList"
                            id="upazila_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{ $t('globalTrans.office') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="officeData.office_id"
                          :options="officeList"
                          :disabled="[3, 10, 11, 19].includes(gridSearch.service_id)"
                          id="office_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" lg="12" sm="12">
                  <b-card no-body>
                    <b-tabs v-model="tabIndex">
                      <b-tab v-for="(item, index) in tabData" :key="index" :active="index === 0" @click="tabChange(index)">
                        <template v-slot:title>
                          <span>{{ local === 'bn' ? item.tab_title_bn : item.tab_title }}</span>
                        </template>
                        <template :id="index">
                          <b-overlay :show="loading">
                            <b-row>
                              <b-col md="12" lg="12" sm="12">
                                <grid-layout :layout.sync="layout"
                                  :col-num="colNum"
                                  :row-height="13"
                                  :is-draggable="false"
                                  :is-resizable="false"
                                  :vertical-compact="true"
                                  :use-css-transforms="true"
                                  :key="layoutKey"
                                >
                                  <grid-item :class="formIData[dindex].field_name === 'product_ve_4186' ? 'product-index' : 'other-index'" style="height: auto !important" v-for="(item, dindex) in layout"
                                    :x="item.x"
                                    :y="item.y"
                                    :w="item.w"
                                    :h="item.h"
                                    :i="item.i"
                                    :dataType="item.dataType"
                                    :key="dindex"
                                  >
                                  <slot v-if="formIData[dindex].field_name === 'product_ve_4186'">
                                    <CustDamDropdown :data="formIData[dindex]" :productList="formIData.find(dt => dt.field_name === 'product_de_4058')" :key="componentKey" v-if="item.dataType === 'Dropdown'"/>
                                  </slot>
                                  <slot v-else-if="formIData[dindex].field_name === 'product_de_4058'">
                                  </slot>
                                  <b-row v-else>
                                    <Input :addMoreFields="addMoreFields" :tmpData="formIData" :index="dindex" v-if="item.dataType === 'InputField'" />
                                    <Dropdown :data="formIData[dindex]" :drpChangeDatas="getChildDropdowns(39)" :drpChangeData="getChildDropdown(dindex)" :nextIndex="dindex + 1" @updateDropdownData="updateDropdown" :key="componentKey" v-if="item.dataType === 'Dropdown'"/>
                                    <Checkbox :data="formIData[dindex]" v-if="item.dataType === 'Checkbox'"/>
                                    <Attachment :data="formIData[dindex]" v-if="item.dataType === 'Attachment'"/>
                                    <Radio :data="formIData[dindex]" v-if="item.dataType === 'Radio'"/>
                                    <Textarea :data="formIData[dindex]" v-if="item.dataType === 'Textarea'"/>
                                    <Paragraph :data="formIData[dindex]" v-if="item.dataType === 'Paragraph'"/>
                                    <Date :addMoreFields="addMoreFields" :data="formIData[dindex]" v-if="item.dataType === 'Date'"/>
                                    <Header :data="formIData[dindex]" v-if="item.dataType === 'Header'"/>
                                    <Payment :data="formIData[dindex]" v-if="item.dataType === 'Payment'" />
                                    <PaymentCheckbox :data="formIData[dindex]" v-if="item.dataType === 'PaymentCheckbox'" />
                                    <Empty :data="formIData[dindex]" v-if="item.dataType === 'Empty'"/>
                                    <SameAsField @updateSameData="updateSameAsData" :data="formIData[dindex]" v-if="item.dataType === 'SameAs'"/>
                                  </b-row>
                                  <template v-if="item.dataType === 'RelationalField'">
                                    <b-row>
                                      <RelationalCheckbox @showComponents="componentsShow" :data="formIData[dindex]"/>
                                      <RelationalDetails :tmpData="relationDataAll[item.i]"/>
                                    </b-row>
                                  </template>
                                  <template v-if="item.dataType === 'Add More'">
                                    <b-row>
                                      <AddMore @addDetails="addMoreDetailsShow" :tmpData="formIData[dindex]" :data="formIData" v-if="item.dataType === 'Add More'" />
                                      <AddMoreDetails :tmpData="detailsDataAll[item.i]" :colData="getLayoutItemColumnsData(item)" class="mt-3"/>
                                    </b-row>
                                  </template>
                                  </grid-item>
                                </grid-layout>
                              </b-col>
                            </b-row>
                          </b-overlay>
                          <div class="row">
                            <div class="col text-right">
                              <b-button v-if="index !== 0" @click="tabIndex--" variant="primary" class="mr-2">{{ $t('component_settings.prev_tab') }}</b-button>
                              <b-button v-if="index < totalTab && index !== totalTab" @click="save_status === 2" type="submit" variant="primary" class="mr-2">{{ $t('component_settings.next_tab') }}</b-button>
                              <b-button v-if="formActions.isDraft && index === totalTab" @click="saveFormData(1)" variant="secondary" class="mr-2">{{$t('component_settings.save_draft')}}</b-button>
                              <b-button v-if="formActions.isSubmit && index === totalTab" @click="saveFormData(2)" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                              <!-- <b-button v-if="formActions.isReset && index === totalTab" @click="reset()" variant="warning" class="mr-1">{{ $t('globalTrans.reset') }}</b-button> -->
                              <router-link v-if="formActions.isCancel && index === totalTab" to="application" :class="'btn btn-danger mr-1'">{{ $t('globalTrans.cancel') }}</router-link>
                              <b-button v-if="formActions.isPayment && index === totalTab" @click="reset()" variant="warning" class="mr-1">{{ $t('component_settings.payment') }}</b-button>
                            </div>
                          </div>
                        </template>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
  </b-container>
</template>
<style>
.bootstrap-select.form-control.input-group-btn {
    z-index: auto;
}
</style>
<script>
import flatpickr from 'flatpickr'
import Input from '@/components/license/user/input-field'
import Empty from '@/components/license/user/empty-field'
import Dropdown from '@/components/license/user/dropdown-field'
import CustDamDropdown from '@/components/license/user/customize-dam/dropdown-field'
import Checkbox from '@/components/license/user/checkbox-field'
import Attachment from '@/components/license/user/attachment-field-edit'
import Radio from '@/components/license/user/radio-field'
import Textarea from '@/components/license/user/textarea-field'
import Paragraph from '@/components/license/user/paragraph-field'
import Date from '@/components/license/user/date-field'
import Header from '@/components/license/user/header-field'
import AddMore from '@/components/license/user/addMore-field'
import AddMoreDetails from '@/components/license/user/addMoreDetails'
import Payment from '@/components/license/user/payment'
import PaymentCheckbox from '@/components/license/user/payment-checkbox'
import RelationalCheckbox from '@/components/license/user/relational-checkbox'
import RelationalDetails from '@/components/license/user/dissableField/relational-details'
import SameAsField from '@/components/license/user/sameas-field'
import { GridLayout, GridItem } from 'vue-grid-layout'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { applicationFormData, applicationDataUpdate, userEntryStore, loadOldFormData, olExpireData } from '../../api/routes'
import { wordsToSnake, snakeToWords } from '@/Utils/fliter'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
export default {
  components: {
    Empty,
    Input,
    Dropdown,
    CustDamDropdown,
    Checkbox,
    Attachment,
    GridLayout,
    GridItem,
    ValidationObserver,
    ValidationProvider,
    Radio,
    Textarea,
    Paragraph,
    Date,
    Header,
    AddMore,
    AddMoreDetails,
    Payment,
    PaymentCheckbox,
    RelationalCheckbox,
    RelationalDetails,
    SameAsField
  },
  data () {
    return {
      layoutKey: 0,
      officeTypeShow: false,
      officeCategoryShow: false,
      save_status: 1,
      isOfficeSelected: false,
      officeItemSHow: false,
      ItemShow: false,
      inputFieldShow: false,
      ItemShowPer: false,
      locationShow: false,
      CityCorpItemShow: false,
      CityCorpItemPerShow: false,
      PauroshobaItemShow: false,
      PauroshobaItemPerShow: false,
      UnionItemShow: false,
      UnionItemPerShow: false,
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.submit'),
      gridSearch: {
        service_id: 0,
        org_id: 0
      },
      prevManualData: {
        hasManual: false,
        generate_id: '',
        issue_date: '',
        expire_date: '',
        reniew_issue_date: '',
        last_step: '',
        book_no: '',
        old_attachment: []
      },
      layout: [],
      layoutData: [],
      colNum: 12,
      index: 0,
      tindex: 0,
      unitLoad: false,
      loading: false,
      isFinal: false,
      stepList: [],
      formInputData: [],
      formIData: [],
      tabData: [],
      tabIndex: 0,
      componentKey: 0,
      formStatus: 0,
      addMore: false,
      detailsData: [],
      Addcolumns: [],
      serviceNamesList: [],
      addMoreFields: [],
      step_id: '',
      totalTab: 0,
      formActions: {
        isDraft: false,
        isSubmit: false,
        isReset: false,
        isPayment: false,
        isCancel: false
      },
      service_name: '',
      sInfo: {},
      saveloading: false,
      officeData: {
        area_type_id: 0,
        city_corporation_id: 0,
        pauroshoba_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: '0',
        office_type_id: 0,
        office_id: 0,
        office_cat_id: 0
      },
      hasManualDisabled: false,
      relationDataAll: [],
      districtList: [],
      pauroshobaList: [],
      cityCorporationList: [],
      badcOffice: [],
      upazilaList: [],
      officeList: [],
      addIndex: '',
      addMoreLayoutData: [],
      detailsDataAll: {},
      isShowManual: false,
      moaLic: '',
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl
    }
  },
  created () {
    this.index = this.layout.length
    this.serviceNamesList = this.getServiceList(parseInt(this.$route.query.org_id))
    this.gridSearch.org_id = parseInt(this.$route.query.org_id)
    this.gridSearch.service_id = parseInt(this.$route.query.service_id)
    this.stepList = this.getServiceStep(parseInt(this.$route.query.service_id))
    this.step_id = parseInt(this.$route.query.step_id)
    // this.loadFrom(parseInt(this.$route.query.service_id))
  },
  mounted () {
    flatpickr('#datepicker', {})
    flatpickr('#expireDate', {})
    if (this.$route.query.org_id && this.$route.query.service_id) {
      this.gridSearch.org_id = parseInt(this.$route.query.org_id)
      this.gridSearch.service_id = parseInt(this.$route.query.service_id)
    }
  },
  watch: {
    local: function () {
      if (this.gridSearch.org_id) {
        this.serviceNamesList = this.getServiceList(this.gridSearch.org_id)
      }
      if (this.officeData.division_id) {
       this.districtList = this.getDistrictList(this.officeData.division_id)
      }
      if (this.officeCategoryShow) { // only for those org who have Office Category from Service Master
        let officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0 && item.org_id === this.gridSearch.org_id)
        officeList = officeList.filter(item => item.district_id === this.officeData.district_id && item.office_cat_id === this.officeData.office_cat_id)
        this.officeList = officeList
      }
    },
    'prevManualData.hasManual': function (newVal, oldVal) {
      if (newVal === true) {
        setTimeout(() => {
          flatpickr('#datepicker', {})
          flatpickr('#expireDate', {})
        }, 500)
      }
    },
    'gridSearch.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === 1) {
          this.moaLic = 'SW/MOA/'
        } else {
          this.moaLic = ''
        }
        this.serviceNamesList = this.getServiceList(newVal)
      }
    },
    'gridSearch.service_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeCategory()
        this.getInitialStep(newVal)
        this.service_name = this.getServiceName(newVal)
        this.step_name = this.getStepName(newVal)
        this.checkOfficeExist(newVal)
        this.loadFrom(newVal)
        this.getServiceLastStep(newVal)
      }
    },
    tabIndex: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateLayout()
      }
    },
    'officeData.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
      if (this.gridSearch.service_id === 3 || this.gridSearch.service_id === 10 || this.gridSearch.service_id === 11) {
        this.officeList = this.getOfficeList(this.officeData.office_type_id)
      } else {
        if (this.officeCategoryShow) { // only for those org who have Office Category from Service Master
          this.officeList = this.getOfficeListforOfcCategory()
        }
      }
    },
    'officeData.district_id': function (newVal, oldVal) {
      this.cityCorporationList = this.getCityCorporationList(newVal)
      this.upazilaList = this.getUpazilaList(newVal)
      if (this.officeCategoryShow) { // only for those org who have Office Category from Service Master
        let officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0 && item.org_id === this.gridSearch.org_id)
        officeList = officeList.filter(item => item.district_id === this.officeData.district_id && item.office_cat_id === this.officeData.office_cat_id)
        this.officeList = officeList
      }
      if ([3, 10, 11, 19].includes(this.gridSearch.service_id)) {
        this.officeList = this.getOfficeList(this.officeData.office_type_id)
        this.badcOfficeSearch()
      }
    },
    'officeData.upazilla_id': function (newVal, oldVal) {
      if ([3, 10, 11, 19].includes(this.gridSearch.service_id)) {
        this.badcOfficeSearch()
      }
    },
    'officeData.city_corporation_id': function () {
      this.officeList = this.getOfficeList(this.officeData.office_type_id)
    },
    'officeData.office_type_id': function (newValue) {
      if (!this.officeCategoryShow) { // only for those org who have not Office Category from Service Master
      this.officeList = this.getOfficeList(newValue)
      }
    }
  },
  computed: {
    manualList: function () {
      return [
        { value: true, text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes' },
        { value: false, text: this.$i18n.locale === 'bn' ? 'না' : 'No' }
      ]
    },
    officeCategoryList: function () {
      return [
        { value: 2, text: this.$i18n.locale === 'en' ? 'District Office' : 'জেলা কার্যালয়' }
      ]
    },
    local: function () {
      return this.$i18n.locale
    },
    officeTypeList: function () {
      let officeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === this.gridSearch.org_id)
      if (this.gridSearch.service_id === 3 || this.gridSearch.service_id === 10) {
        officeTypeList = officeTypeList.filter(item => item.value === 4)
      }
      if (this.gridSearch.service_id === 11) {
        officeTypeList = officeTypeList.filter(item => item.value === 72)
      }
      if (this.gridSearch.service_id === 19) {
        officeTypeList = officeTypeList.filter(item => item.value === 132)
      }
      return officeTypeList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0).map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    areaTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'সিটি কর্পোরেশন' : 'City Corpoation' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'পৌরসভা' : 'Pauroshoba' },
        { value: 3, text: this.$i18n.locale === 'bn' ? 'উপজেলা' : 'Upazila' }
      ]
      return list
    },
    divisionList: function () {
      const divList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
      return divList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    }
  },
  methods: {
    oldApplicationInfo () {
      const params = {
        service_id: this.gridSearch.service_id,
        generate_id: this.prevManualData.generate_id,
        issue_date: this.prevManualData.issue_date,
        reniew_issue_date: this.prevManualData.reniew_issue_date
      }
      RestApi.getData(licenseRegistrationServiceBaseUrl, olExpireData, params).then(response => {
        if (response.success) {
          this.prevManualData.expire_date = response.data.expire_date
        } else {
          this.prevManualData.expire_date = ''
        }
      })
    },
    isOfficeWiseCheck () {
      if (parseInt(this.$route.query.newOrReniew)) {
        return true
      }
      return this.isOfficeSelected
    },
    seedOfficeCheck () {
      if (parseInt(this.$route.query.newOrReniew)) {
        return true
      }
      return false
    },
    badcOfficeSearch () {
      let badcOffice = this.badcOffice
      if ([11, 19].includes(this.gridSearch.service_id)) {
        badcOffice = badcOffice.find(item => item.district_id === this.officeData.district_id)
      } else {
        badcOffice = badcOffice.find(item => item.upazilla_id === this.officeData.upazilla_id)
      }
      if (typeof badcOffice !== 'undefined') {
        this.officeData.office_id = badcOffice.region_office_id
      } else {
        this.officeData.office_id = 0
      }
    },
    updateSameAsData (updata, status) {
      if (updata && status) {
        updata.fields.forEach((i, index) => {
          const sameField = updata.same_fields[index]
          const formField = this.formInputData.find(item => item.id === i)
          const formSameField = this.formInputData.find(item => item.id === sameField)
          formSameField.value = formField.value
        })
      } else {
        updata.same_fields.forEach(i => {
          const formSameField = this.formInputData.find(item => item.id === i)
          formSameField.value = ''
        })
      }
    },
    onFileChange (e) {
        this.getBase64(e.target.files[0]).then(res => {
          this.prevManualData.old_attachment[0] = res
        })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    officeCategory () { // only for those org who have Office Category from Service Master
      const serviceData = this.serviceNamesList.find(item => item.org_id === this.gridSearch.org_id)
      if (serviceData.office_category_check === 1) { // Office Type and Office Category Show Condition
        this.officeTypeShow = false
        // this.isOfficeSelected = false
        // this.locationShow = false
        this.officeCategoryShow = true
      } else {
        this.officeData.office_cat_id = 0
        this.officeCategoryShow = false
        this.officeTypeShow = true
      }
    },
    getAreaTypeData (typeId) {
      this.officeItemSHow = true
      this.ItemShow = true
      if (typeId === 1) {
        this.CityCorpItemShow = true
        this.PauroshobaItemShow = false
        this.UnionItemShow = false
      } else if (typeId === 2) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = true
        this.UnionItemShow = false
      } else if (typeId === 3) {
        this.CityCorpItemShow = false
        this.PauroshobaItemShow = false
        this.UnionItemShow = true
      }
    },
    getChildDropdowns (currentIndex) {
      const child = this.formIData.find((item, index) => (item.id === 2818))
      return child
    },
    getChildDropdown (currentIndex) {
      const currentName = this.formIData[currentIndex].dropdownData.dropdown_name
      const child = this.formIData.find((item, index) => (currentIndex < index && item.field_type === 'dropdown' && item.dropdownData.parent_state === currentName))
      return child
    },
    getInitialStep (serviceId) {
      const tmpStep = this.$store.state.ExternalLrcpn.lrcpnObj.serviceSteps.find(step => step.service_id === serviceId && step.order === 1)
      this.step_id = typeof tmpStep !== 'undefined' ? tmpStep.step_id : 0
    },
    getEntryStep (serviceId) {
      const tmpService = this.$store.state.ExternalLrcpn.lrcpnObj.serviceStepForm.find(item => (item.service_id === serviceId && item.form_type === 2))
      if (typeof tmpService === 'undefined') {
        return {}
      } else {
        return tmpService
      }
    },
    getServiceStep (sId) {
      const stepList = this.$store.state.ExternalLrcpn.lrcpnObj.serviceSteps.filter(item => item.service_id === sId)
      const tmpList = stepList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.step_id, text: item.step_name_bn, order: item.order }
        } else {
          return { value: item.step_id, text: item.step_name, order: item.order }
        }
      })
      return tmpList.sort((a, b) => { return a.order - b.order })
    },
    loadFrom (serviceId) {
      const formData = this.$store.state.ExternalLrcpn.lrcpnObj.serviceStepForm.find(item => item.service_id === serviceId && item.form_type === 2)
      if (typeof formData === 'undefined') {
        this.layoutData = []
        this.formInputData = []
        this.tabData = []
        this.layout = []
      } else {
        const formId = formData.form_id
        this.unitLoad = true
        const params = {
          id: formId,
          service_id: this.$route.query.service_id,
          step_id: this.getGridStep(this.gridSearch.service_id).step_id,
          applicationID: parseInt(this.$route.query.application_id)
        }
        RestApi.getData(licenseRegistrationServiceBaseUrl, applicationFormData, params).then(response => {
          this.badcOffice = response.badcOffice
          this.loading = false
          if (isNotEmpty(response.data)) {
            this.isShowManual = true
            if (response.data.applicationInfo.type === 2 && response.data.applicationInfo.old_manual_id !== '') {
              this.prevManualData.hasManual = true
              this.prevManualData.generate_id = response.data.applicationInfo.old_manual_id
              this.prevManualData.expire_date = response.data.applicationInfo.old_expire_date
              this.prevManualData.reniew_issue_date = response.data.applicationInfo.reniew_issue_date
              this.prevManualData.issue_date = response.data.applicationInfo.old_application[0].issue_date
              this.prevManualData.old_attachment[0] = response.data.applicationInfo.old_attachment
              this.prevManualData.last_step = ''
              this.prevManualData.book_no = response.data.applicationInfo.old_application[0].book_no
            }
            if (parseInt(this.$route.query.type)) {
              if (parseInt(this.$route.query.type) === 2) {
                this.prevManualData.hasManual = true
                if (!this.prevManualData.issue_date) {
                  this.prevManualData.issue_date = response.data.applicationInfo.issue_date
                }
                this.prevManualData.reniew_issue_date = response.data.applicationInfo.issue_date
                if (response.data.applicationInfo.old_expire_date) {
                  this.prevManualData.reniew_issue_date = response.data.applicationInfo.old_expire_date
                }
                this.prevManualData.expire_date = response.data.applicationInfo.expire_date
                this.prevManualData.generate_id = response.data.applicationInfo.generate_id
                // this.prevManualData.old_attachment = []
              }
            }
            this.getAreaTypeData(response.data.applicationInfo.area_type_id)
            this.officeData = response.data.applicationInfo
            this.gridSearch.file_no = response.data.applicationInfo.file_no
            this.formActions = JSON.parse(response.data.item.form_action)
            this.layoutData = []
            this.formInputData = []
            this.tabData = []
            this.locationShow = true
            response.data.item.tabs.forEach((item, tabIndex) => {
              const tabNo = item.tab_no
              const tabName = item.tab_name
              const tabTitle = snakeToWords(item.tab_name)
              const tabTitleBn = snakeToWords(item.tab_title_bn)
              const data = { tab_no: tabNo, tab_name: tabName, tab_title: tabTitle, tab_title_bn: tabTitleBn }
              this.tabData.push(data)
              let sameField = ''
              let sameAsField = ''
              let sameId = ''
              item.layouts.forEach((layItem) => {
                const layJson = JSON.parse(layItem.layout)
                const dataJson = JSON.parse(layItem.data)
                this.layoutData.push(layJson)
                if (response.data.applicationData !== null) {
                  response.data.applicationData.map((item, index) => {
                      if (item !== null) {
                      this.table_id = item.id
                      this.BackButtonShow = true
                      Object.keys(item).forEach(key => {
                          if (key === dataJson.field_name) {
                            if (dataJson.field_type === 'options') {
                              Object.assign(dataJson, { value: item[key] })
                            } else if (dataJson.field_type === 'addMore_button') {
                              Object.assign(dataJson, { addMore_options: JSON.parse(item[key]) })
                              this.addMoreDetailsShow(JSON.parse(item[key]), dataJson.id)
                            } else if (dataJson.field_type === 'RelationalField') {
                              Object.assign(dataJson, { value: item[key] })
                            } else if (dataJson.field_type === 'number' && dataJson.field_name.match('mobile')) {
                              if (item[key]) {
                                const dbNumber = item[key].toString()
                                const number = 0 + dbNumber
                                Object.assign(dataJson, { value: number })
                              } else {
                                const number = 0
                                Object.assign(dataJson, { value: number })
                              }
                            } else if (dataJson.field_type === 'number' && dataJson.field_name.match('phone')) {
                              let dbNumber = item[key]
                              if (item[key]) {
                                dbNumber = item[key].toString()
                              }
                              const number = 0 + dbNumber
                              Object.assign(dataJson, { value: number })
                            } else {
                              Object.assign(dataJson, { value: item[key] })
                            }
                          }
                      })
                      } else {
                      this.butonShow = true
                      }
                  })
                } else {
                  this.butonShow = true
                }
                this.formInputData.push(dataJson)
                this.tindex++
                if (dataJson.field_type === 'sameAs_button') {
                  sameField = dataJson.fields[0]
                  sameAsField = dataJson.same_fields[0]
                  sameId = dataJson.id
                }
              })
              if (typeof sameField !== 'undefined' && typeof sameAsField !== 'undefined' && typeof sameId !== 'undefined') {
                const fieldDet = this.formInputData.find(item => item.id === sameField)
                const samFielDet = this.formInputData.find(item => item.id === sameAsField)
                if (typeof fieldDet !== 'undefined' && typeof samFielDet !== 'undefined') {
                  if (fieldDet.value === samFielDet.value) {
                    const samelayout = this.formInputData.find(item => item.id === sameId)
                    samelayout.value = 1
                  }
                }
              }
              const addMoreLayoutData = this.layoutData.filter(item => item.dataType === 'Add More' && item.tabIndex === tabIndex)
              this.addMoreLayoutData = addMoreLayoutData
              if (addMoreLayoutData.length) {
                addMoreLayoutData.forEach((addMoreLayoutItem) => {
                  const cols = []
                  cols.push({ text_en: 'SL', text_bn: 'ক্রমিক নং' })
                  const fieldData = this.formInputData.find(item => item.id === addMoreLayoutItem.i)
                  fieldData.fields.forEach(i => {
                    const field = this.formInputData.find(item => item.id === i)
                    cols.push({ text_en: field.label, text_bn: field.label_bn })
                  })
                  cols.push({ text_en: 'Action', text_bn: 'অ্যাকশন' })
                  this.Addcolumns.push(cols)
                  this.addMore = true
                  this.addIndex = addMoreLayoutItem.tabIndex
                  this.detailsDataAll[addMoreLayoutItem.i] = []
                })
              }
            })
            this.totalTab = this.tabData.length - 1
            const addMoreFields = this.formInputData.filter(inpFilter => (inpFilter.field_type === 'addMore_button'))
            const moreField = []
            addMoreFields.forEach(addMore => {
              addMore.fields.forEach(field => {
                moreField.push(field)
              })
            })
            this.addMoreFields = moreField
          }
          this.unitLoad = false
          this.updateLayout()
          this.loadApplicationData()
          if (this.gridSearch.service_id === 11) {
            this.prevManualData.hasManual = true
            this.hasManualDisabled = true
          }
          if (this.gridSearch.service_id === 19 && this.officeData.circular_id) {
            this.hasManualDisabled = true
          }
        })
      }
    },
    async saveFormData (Sstatus) {
      this.save_status = Sstatus
      const lastTab = this.tabData.length - 1
      const currentTab = this.tabIndex
      if (lastTab > currentTab) {
        this.tabIndex++
        return false
      }
      const sData = []
      const pData = []
      const data = this.formInputData
      this.tabData.forEach((tabItem) => {
        const tData = {}
        const fData = {}
        fData.images = []
        data.forEach((item) => {
          if (item.indexId === tabItem.tab_no) {
            if (item.field_type !== 'empty' && item.field_type !== 'paragraph' && item.field_type !== 'image' && item.field_type !== 'h1' && item.field_type !== 'h2' && item.field_type !== 'h3' && item.field_type !== 'h4' && item.field_type !== 'h5' && item.field_type !== 'h6' && item.field_type !== 'sameAs_button') {
              if (item.field_type === 'options') {
                if (item.value) {
                  tData[item.field_name] = item.value.toString()
                } else {
                  tData[item.field_name] = ''
                }
              } else if (item.field_type === 'dropdown') {
                if (item.field_name === 'product_ve_4186' || item.field_name === 'product_de_4058') {
                  if (Array.isArray(item.value)) {
                    tData[item.field_name] = JSON.stringify(item.value)
                  } else {
                    const fieldData = []
                    fieldData.push(item.value)
                    tData[item.field_name] = JSON.stringify(fieldData)
                  }
                } else {
                  tData[item.field_name] = item.value
                }
              } else if (item.field_type === 'paymentCheckbox') {
                if (Array.isArray(item.value)) {
                  tData[item.field_name] = JSON.stringify(item.value)
                } else {
                  const fieldData = []
                  fieldData.push(item.value)
                  tData[item.field_name] = JSON.stringify(fieldData)
                }
              } else if (item.field_type === 'addMore_button') {
                tData[item.field_name] = JSON.stringify(item.addMore_options)
              } else {
                tData[item.field_name] = item.value
              }
            }
            if (item.field_type === 'image' && item.upload_type === 'dynamic') {
              tData[item.field_name] = item.value
              fData.images.push({ field_name: item.field_name, value: item.value, fieldType: item.field_type, tabId: item.indexId })
            }
            if (item.field_type === 'file') {
              tData[item.field_name] = item.value
              if (item.value && typeof item.value !== 'string') {
                fData.images.push({ field_name: item.field_name, value: item.value, fieldType: item.field_type, tabId: item.indexId })
              }
            }
          }
        })
        sData.push(tData)
        pData.push(fData)
      })
      this.sInfo.save_status = this.save_status
      this.sInfo.application_id = parseInt(this.$route.query.application_id)
      this.sInfo.org_id = this.gridSearch.org_id
      this.sInfo.service_id = this.gridSearch.service_id
      this.sInfo.service_name = wordsToSnake(this.service_name)
      this.sInfo.step_id = this.getEntryStep(this.gridSearch.service_id).step_id
      this.sInfo.step_id = this.getGridStep(this.gridSearch.service_id).step_id
      this.sInfo.step_name = wordsToSnake(this.step_name)
      this.sInfo.tabInfo = this.tabData
      if (parseInt(this.$route.query.type)) {
        this.officeData.type = parseInt(this.$route.query.type)
      } else {
        this.officeData.type = 1
      }
      const formData = {
        formInputData: this.formInputData,
        data: sData,
        service_info: this.sInfo,
        officeData: this.officeData,
        fileData: pData,
        prevManualData: this.prevManualData
      }
      let result = null
      this.saveloading = true
      if (this.officeData.type === 2) {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, userEntryStore, formData)
      } else {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${applicationDataUpdate}/${this.table_id}`, formData)
      }
      this.saveloading = false
      if (result.success === 'error') {
        this.$toast.error({
          title: 'Error',
          message: result.message,
          color: '#D6E09B'
        })
        return
      }
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/lrcpn/application')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    reset () {
    },
    updateDropdown: function (updata, nindex) {
      this.componentKey += 1
    },
    tabChange (val) {
      this.tabIndex = val
      this.updateLayout()
    },
    async addMoreDetailsShow (data, index = null) {
      if (data) {
        await this.$nextTick(() => {
          this.detailsData = data
          this.detailsDataAll[index] = data
          this.detailsDataAll = Object.assign({}, this.detailsDataAll, { index: data })
        })
      }
    },
    componentsShow (data, id, value) {
      if (value === true) {
        const relData = {
          layout: [],
          formIData: []
        }
        let height = 0
        data.map(item => {
          const layFind = this.layoutData.find(litem => litem.i === item && litem.tabIndex === this.tabIndex)
          height = height + layFind.h
          const inpFind = this.formInputData.find(litem => litem.id === item && litem.indexId === this.tabIndex)
          relData.layout.push(layFind)
          relData.formIData.push(inpFind)
        })
        const layoutH = this.layout.find(item => item.i === id)
        layoutH.h = height
        this.relationDataAll[id] = relData
      } else {
        const layoutH = this.layout.find(item => item.i === id)
        layoutH.h = 2
        const relData = {
          layout: [],
          formIData: []
        }
        this.relationDataAll[id] = relData
      }
      this.layout.push()
    },
    updateLayout: function (val) {
      this.layout = []
      this.formIData = []
      this.layoutData.map((item, index) => {
        const layData = item.tabIndex === this.tabIndex
        const inpData = this.formInputData[index]
        if (layData === true) {
          this.layout.push(item)
          this.formIData.push(inpData)
        }
      })
      this.layoutKey = this.layoutKey + 1
    },
    getServiceList (orgId) {
      return this.$store.state.ExternalLrcpn.lrcpnObj.serviceNamesList.filter(item => (item.org_id === orgId))
    },
    getServiceName (sId) {
      const service = this.$store.state.ExternalLrcpn.lrcpnObj.serviceNamesList.find(item => item.value === parseInt(sId))
     return typeof service !== 'undefined' ? service.text_en : ''
    },
    getStepName (stId) {
      const step = this.$store.state.ExternalLrcpn.lrcpnObj.stepNamesList.find(item => item.value === parseInt(stId))
      return typeof step !== 'undefined' ? step.text_en : ''
    },
    getDistrictList (divisionId = null) {
      let districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        districtList = districtList.filter(district => district.division_id === divisionId)
      }
      return districtList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getCityCorporationList (districtId) {
      const objectData = this.$store.state.ExternalLrcpn.commonObjCommonConfig.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
        return objectData.map((obj, key) => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
        })
    },
    getUpazilaList (districtId = null) {
      let upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
         upazilaList = upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getOfficeTypeList (serviceId) {
      const officeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList
      if (serviceId) {
        return officeTypeList.filter(service => service.service_id === serviceId)
      }
      return officeTypeList
    },
    getOfficeList (officeTypeId = 0) {
      const officeListData = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0 && item.org_id === this.gridSearch.org_id)
      const officeList = officeListData.filter(item => item.office_type_id === parseInt(officeTypeId))
      return officeList
    },
    getGridStep (serviceId) {
      const tmpService = this.$store.state.ExternalLrcpn.lrcpnObj.serviceStepForm.find(item => (item.service_id === serviceId && item.form_type === 2))
      if (typeof tmpService === 'undefined') {
        return {}
      } else {
        return tmpService
      }
    },
    getOfficeListforOfcCategory () {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0 && item.org_id === this.gridSearch.org_id)
      this.officeList = []
      if (this.officeData.office_cat_id === 1) {
        return officeList.filter(item => item.division_id === this.officeData.division_id && item.office_cat_id === this.officeData.office_cat_id)
      }
      if (this.officeData.office_cat_id === 2) {
        return officeList.filter(item => item.district_id === this.officeData.district_id && item.office_cat_id === this.officeData.office_cat_id)
      }
      if (this.officeData.office_cat_id === 3) {
        return officeList.filter(item => item.upazilla_id === this.officeData.upazilla_id && item.office_cat_id === this.officeData.office_cat_id)
      }
      return officeList
    },
    checkOfficeExist (serviceId) {
      const service = this.$store.state.ExternalLrcpn.lrcpnObj.serviceNamesList.find(item => (item.value === serviceId))
      if (service.office_type_id === null) {
        this.isOfficeSelected = false
      } else {
        this.isOfficeSelected = true
        this.officeData.office_type_id = service.office_type_id
        this.officeData.office_id = service.office_name_id
      }
      if (this.officeCategoryShow) {
        this.isOfficeSelected = false
      }
    },
    getLayoutItemColumnsData (addMoreLayoutItem) {
      const cols = []
      cols.push({ text_en: 'SL', text_bn: 'ক্রমিক নং' })
      const fieldData = this.formInputData.find(item => item.id === addMoreLayoutItem.i)
      fieldData.fields.forEach(i => {
        const field = this.formInputData.find(item => item.id === i)
        cols.push({ text_en: field.label, text_bn: field.label_bn })
      })
      cols.push({ text_en: 'Action', text_bn: 'অ্যাকশন' })
      return cols
    },
    getServiceLastStep (serviceId) {
      const tmpStepList = this.$store.state.ExternalLrcpn.lrcpnObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId))
      const maxorder = tmpStepList.reduce(function (prev, current) {
        return (prev.order > current.order) ? prev : current
      })
      this.prevManualData.last_step = maxorder.order
    },
    loadApplicationData () {
      const formData = this.$store.state.ExternalLrcpn.lrcpnObj.serviceStepForm.find(item => item.service_id === this.gridSearch.service_id && item.form_type === 2)
      if (typeof formData !== 'undefined') {
        const formId = formData.form_id
        const params = {
          form_id: formId,
          service_id: this.gridSearch.service_id,
          grid_step_id: this.getEntryStep(this.gridSearch.service_id).step_id,
          tabList: this.getTabList()
        }
        RestApi.getData(licenseRegistrationServiceBaseUrl, loadOldFormData, params).then(response => {
          if (response.success) {
            if (response.data && response.app_data) {
              Object.keys(response.app_data).forEach(key => {
                const data = response.data.details.find(item => item.field_id_to === key)
                if (typeof data !== 'undefined') {
                  const input = this.formInputData.find(item => item.field_name === key)
                  if (typeof input !== 'undefined') {
                    input.value = response.app_data[key]
                  }
                }
              })
            }
          }
        })
      }
    },
    getTabList () {
      const formId = this.getEntryStep(this.gridSearch.service_id).form_id
      const form = this.$store.state.ExternalLrcpn.lrcpnObj.dynamicFormList.find(item => item.value === formId)
      if (typeof form === 'undefined') {
        return []
      }
      return form.tabs
    }
  }
}
</script>
<style scoped>
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.fields {
  padding: 0.375rem 0.75rem;
}
.fields:hover {
  z-index: 1;
  color: #fff;
  background-color: #5a6268;
  border-radius: 0.25rem;
}
.dark_button .btn-secondary {
  color: #000 !important;
}
hr {
    border: none;
    border-top: 3px double #333;
    color: #333;
    overflow: visible;
    text-align: center;
    height: 5px;
}
hr:after {
    background: #fff;
    content: '';
    padding: 0 4px;
    position: relative;
    top: -13px;
}
</style>
